<script>
  import Flatpickr from 'svelte-flatpickr/src/Flatpickr.svelte'
  import language from "flatpickr/dist/l10n/fr.js"

  import PaymentForm from './PaymentForm'

  import Loader from "../common/Loader.svelte";
  import { getHeaders } from "../headers.svelte";
  import { processePotentialNotification, notification } from '../common/notificationStore.js';
  import { pluralize } from '../common/utils.js';
  import { fade } from 'svelte/transition';
  import OutClick from 'svelte-outclick'
  import { Icon } from '@steeze-ui/svelte-icon';
  import { ChevronLeft } from '@steeze-ui/heroicons';
  import { LockClosed } from '@steeze-ui/heroicons';
  import Times from "../common/icons/Times.svelte";
  import QuoteSummaryTable from "./QuoteSummaryTable.svelte";
  import GuestlistSelector from "./GuestlistSelector.svelte";
  import RegistrationForm from "./RegistrationForm.svelte";
  import { quote } from './quoteStore.js';
  import { updateOptionsUponSelection, onDayCreate } from './flatpickrBookingHandler';

  let travellersModalOpen, datesModalOpen, submittingBookingRequest = false
  let dateFormatter = new Intl.DateTimeFormat('fr-FR', { dateStyle: 'medium'})
  let paymentFormComponent, submissionError

  export let listingImageUrl, listingOwnerName, listingTitle, initialQuote, traveller, listingId, bookedPeriods, animalsAccepted, durationConfig, cancellationPolicy, capacity, onlinePayment, stripePublicKey

  let travellerInfo = {}
  $quote = initialQuote

  let datePickerValue = [
    new Date($quote.period.start),
    new Date($quote.period.end)
  ]

  let fp;
  let adultCount = $quote.adultCount

  let flatpickrOptions = {
    allowInput: true,
    locale: language.fr,
    dateFormat: 'j M',
    showMonths: document.body.getBoundingClientRect().width > 620 ? 2 : 1,
    mode: "range",
    inline: true,
    defaultDate: datePickerValue,
    minDate: "today",
    onChange: async function(selectedDates, _dateStr, instance) {
      updateOptionsUponSelection(selectedDates, instance, bookedPeriods)

      if(selectedDates.length == 2 && selectedDates[0].getTime() != selectedDates[1].getTime()) {
        $quote.period.start = flatpickr.formatDate(selectedDates[0], "Y-m-d"),
        $quote.period.end = flatpickr.formatDate(selectedDates[selectedDates.length - 1], "Y-m-d")
        await updateQuote()
      }
      else {
        $quote.period.start = null
        $quote.period.end = null
      }
    },
    onReady: function(selectedDates, _dateStr, instance) {
      updateOptionsUponSelection(selectedDates, instance, bookedPeriods)
    },

    onDayCreate: function(_dObj, _dStr, instance, dayElem){
      onDayCreate(durationConfig, bookedPeriods, instance, dayElem)
    },
  }

  const updateQuote = async () => {
    let alreadyTypedMessage = $quote.message

    const response = await fetch(`/quotes/new?start_date=${encodeURIComponent($quote.period.start)}&end_date=${encodeURIComponent($quote.period.end)}&listing_id=${listingId}&adult_count=${$quote.guestlist.adult_count}&child_count=${$quote.guestlist.child_count}&infant_count=${$quote.guestlist.infant_count}&animal_count=${$quote.guestlist.animal_count}`)

    if (response.ok) {
      const data = await response.json()
      $quote = data
      $quote.message = alreadyTypedMessage
    }
  }

  const submitBookingRequest = async () => {
    if(!$quote.period.start || !$quote.period.end) {
      $notification = { alert: "Veuillez spécifier des dates pour poursuivre" }
      setTimeout(() => $notification = {}, 5000)
      return
    }

    if(!$quote.guestlist.adult_count) {
      $notification = { alert: "Veuillez spécifier des voyageurs pour poursuivre" }
      setTimeout(() => $notification = {}, 5000)
      return
    }

    let bookingRequestParams = {
      listing_id: $quote.listing.id,
      start_date: $quote.period.start,
      end_date: $quote.period.end,
      adult_count: $quote.guestlist.adult_count,
      child_count: $quote.guestlist.child_count,
      infant_count: $quote.guestlist.infant_count,
      animal_count: $quote.guestlist.animal_count,
      quoted_total_price: $quote.total_price,
      message: $quote.message,
    }

    if(!traveller) {
      bookingRequestParams["first_name"] = travellerInfo.first_name
      bookingRequestParams["last_name"] = travellerInfo.last_name
      bookingRequestParams["email"] = travellerInfo.email
      bookingRequestParams["password"] = travellerInfo.password
      bookingRequestParams["phone"] = travellerInfo.phone
    }
    else {
      bookingRequestParams["first_name"] = traveller.first_name
      bookingRequestParams["last_name"] = traveller.last_name
      bookingRequestParams["email"] = traveller.email
      bookingRequestParams["phone"] = traveller.phone
    }

    submittingBookingRequest = true

    if(onlinePayment) {
      const res = await paymentFormComponent.trySubmit()

      if(res.error) {
        submittingBookingRequest = false
        return
      }
    }

    const res = await fetch("/booking_requests", {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({ booking_request: bookingRequestParams })
    })

    const data = await res.json()

    if (res.ok) {
      if(onlinePayment) {
        paymentFormComponent.submitPayment(data.payment_intent_client_secret, data.redirect_url)
      }
      else{
        window.location.href = data.redirect_url
      }
    }
    else {
      processePotentialNotification(data)

      if(data?.notification?.type == "alert") {
        submissionError = data.notification.message
      }
    }

    submittingBookingRequest = false
  }

  const navigateBack = () => {
    const isSafariIOS = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (isSafariIOS) {
      // Use workaround for Safari on iOS
      window.location.href = document.referrer;
    } else {
      // Use history.back() for other browsers
      history.back();
    }
  }

  quote.subscribe(async (value) => {
    if(value.guestlist.adult_count !== adultCount) {
      adultCount = value.guestlist.adult_count
      await updateQuote()
    }
  })

  const onPhoneChange = (iti) => {
    $quote.phone = iti.getNumber()
  }

</script>

  <div class="relative flex items-center py-8">
    <div class="absolute left-0 cursor-pointer" on:click={ () => navigateBack()} >
      <Icon src={ChevronLeft} size="24px" theme="solid"/>
    </div>

    <h1 class="w-full mx-auto text-lg font-bold text-center">Demande de réservation</h1>
  </div>

  <div class="shadow rounded-xl bg-gray-50">
    <div class="flex">
      <img src="{listingImageUrl}" alt="" class="object-cover rounded-l-xl w-28">
      <div class="p-4">
        <h2 class="text-lg font-bold">{listingTitle}</h2>
        <p class="text-sm font-semibold text-gray-600">Chez {listingOwnerName}</p>
      </div>
    </div>
  </div>

  <h2 class="pt-8 text-lg font-bold">Votre voyage</h2>
  <h3 class="flex pt-2 font-bold text-gray-500 ">
    <span>Dates</span>
    <button class="ml-auto btn" on:click="{() => datesModalOpen = true}">Modifier</button>
  </h3>
  <p class="text-sm font-semibold">
    {#if $quote.period.start && $quote.period.end}
      Du {dateFormatter.format(new Date($quote.period.start))} au {dateFormatter.format(new Date($quote.period.end))}
    {:else}
      Dates non définies
    {/if}
  </p>

  {#if datesModalOpen}
    <div transition:fade={{"duration": 100}} class="fixed top-0 left-0 flex items-center justify-center w-screen h-screen z-[60] fix bg-black/80">
      <OutClick on:outclick={() => datesModalOpen = false} >
        <div class="bg-white rounded-lg">
          <div class="flex items-center justify-between p-4 font-bold border-b rounded-t-lg bg-gray-50">
            <div>
              <h3>Dates</h3>
            </div>
            <span class="p-2 bg-gray-200 rounded-full cursor-pointer hover:bg-gray-300 transition-all" on:click={() => datesModalOpen = false}>
              <Times className="w-4 h-4 text-gray-600" />
            </span>
          </div>
          <div class="p-4 modal-checkout-selector">
            <div class="h-72">
              <Flatpickr options="{flatpickrOptions}" bind:value="{datePickerValue}" bind:flatpickr={fp} />
            </div>
            <div class="mt-8 grid grid-cols-3">
              <button class="block mr-auto btn-link" on:click={() => fp.clear() }>Effacer</button>
              <button class="block btn-primary" on:click={() => datesModalOpen = false}>Valider</button>
              <span></span>
            </div>
          </div>
        </div>
      </OutClick>
    </div>
  {/if}

  <h3 class="flex pt-2 font-bold text-gray-500 ">
    <span>Voyageurs</span>
    <button class="ml-auto btn" on:click={() => travellersModalOpen = true}>Modifier</button>
  </h3>


  {#if travellersModalOpen}
    <div transition:fade={{"duration": 100}} class="fixed top-0 left-0 flex items-center justify-center w-screen h-screen z-[60] fix bg-black/80">
      <OutClick on:outclick={() => travellersModalOpen = false} >
        <div class="bg-white rounded-lg">
          <GuestlistSelector bind:travellersModalOpen animalsAccepted={animalsAccepted} capacity={capacity} />
        </div>
      </OutClick>
    </div>
  {/if}

  <p class="text-sm font-semibold">{pluralize($quote.guestlist.adult_count, "adulte")}</p>
  <p class="text-sm font-semibold">{pluralize($quote.guestlist.child_count, "enfant")}</p>
  <p class="text-sm font-semibold">{pluralize($quote.guestlist.infant_count, "bébé")}</p>
  <p class="text-sm font-semibold">{pluralize($quote.guestlist.animal_count, "animal", "animaux")}</p>

  <div class="my-8 divider"></div>

  <div>
    <h2 class="text-lg font-bold">Paiement</h2>

    {#if onlinePayment }
      <p class="flex items-center py-2 md:items-start explainer">
        <Icon src={LockClosed} size="18px" theme="solid" class="mr-2 shrink-0"/>
        Le paiement est sécurisé grâce à notre partenaire Stripe, leader mondial du paiement en ligne.
      </p>
    {:else}
      <p class="explainer">Paiement en direct avec l'hôte.</p>

      <p class="explainer">L’hôte se charge de vous envoyer le contrat de location.</p>
    {/if}

    <div class="p-8 bg-gray-100 border rounded-lg">
      <h3 class="font-bold text-gray-600">Détail prix</h3>
      <QuoteSummaryTable bind:quote={$quote} />
    </div>

    {#if onlinePayment }
      <PaymentForm bind:this={paymentFormComponent} quote={$quote} stripePublicKey={stripePublicKey} />
    {/if}
  </div>

  <div class="mt-0 divider"></div>

  <form action="" on:submit|preventDefault={submitBookingRequest}>
    {#if !traveller}
      <h2 class="text-lg font-bold">Coordonnées</h2>
      <RegistrationForm bind:travellerInfo={travellerInfo} onPhoneChange={onPhoneChange} />
    {/if}

    <div>
      <h2 class="text-lg font-bold">Message</h2>
      <p class="explainer">Communiquez votre heure d'arrivée et le motif de votre voyage à votre hôte.</p>
      <textarea bind:value={$quote.message} rows="8" maxlength="3000" class="w-full p-4 border-2 rounded-lg placeholder:text-gray-500 focus:outline-primary"
        placeholder="Exemple : Bonjour {listingOwnerName}, nous venons nous ressourcer dans votre belle région quelques jours. Nous prévoyons d'arriver vers 18h." ></textarea>
    </div>

    <div class="my-8 divider"></div>
    <div>
      <h2 class="text-lg font-bold">Conditions</h2>
      {#if cancellationPolicy.length > 0}
        <p class="explainer">
          {cancellationPolicy}
        </p>
      {/if}
    </div>

    <div class="my-8 divider"></div>
    <div class="pb-16">
      <h2 class="text-lg font-bold">Confirmation</h2>
      <p class="explainer">L’hôte a 24h pour valider votre demande de réservation.</p>

      <p class="explainer">
        En cliquant sur le bouton ci-dessous, j’accepte les <a href="/cgu">conditions générales de Toploc</a> et j’envoie ma demande pour de belles vacances nature 🌳
      </p>
      {#if submittingBookingRequest}
        <button class="flex items-center w-full opacity-75 md:w-auto btn-primary gap-x-2" disabled>
          <Loader color="#fff" size="20px"/>
          <span>Envoi en cours</span>
        </button>
      {:else}
        <input value="Envoyer ma demande" type="submit" class="w-full md:w-auto btn-primary">
      {/if}
      {#if submissionError}
        <div class="mt-4 text-sm font-bold text-red-500">
          {submissionError}
        </div>
      {/if}
    </div>
  </form>
