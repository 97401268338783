<script>
  import { filtersOpen } from './listingsStore.js';
  import { onMount, onDestroy } from 'svelte';
  import Loader from "../common/Loader.svelte";

  export let mapIcon, closeIcon

  let mapIsOpen = false
  let mapHasLoaded = false

  const createMap = () => {
    import(/* webpackChunkName: "mapsChunk" */ '../../entrypoints/maps.js').then((module) => {
      module.default()
      dispatchEvent(new Event('toploc:open-map-clicked'))
    })
  }

  const openMap = () => {
    mapIsOpen = true

    if(!mapHasLoaded) {
      createMap()
    }
    else {
      dispatchEvent(new Event('toploc:open-map-clicked'))
    }
  }

  const closeMap = () => {
    mapIsOpen = false
    dispatchEvent(new Event('toploc:close-map-clicked'))
  }

  const setMapHasLoaded = () => {
    mapHasLoaded = true
  }

  onMount(() => {
    window.addEventListener("toploc:map-content-loaded", setMapHasLoaded)

    if(document.body.getBoundingClientRect().width > 1000) {
      openMap();
    }
  });

  onDestroy(() => {
    window.removeEventListener("toploc:map-content-loaded", setMapHasLoaded)
  });
</script>

{#if !$filtersOpen }
  <div class="fixed left-0 z-50 flex justify-center w-full text-center lg:hidden gap-x-6 bottom-6">
    <div class="flex items-center p-3 rounded-full shadow-xl bg-primary margin-auto">
      {#if mapIsOpen}
        <div on:click={closeMap}>
          {@html closeIcon}
        </div>
      {:else}
        <div on:click={openMap}>
          {@html mapIcon}
        </div>
      {/if}
    </div>
  </div>
{/if}

{#if mapIsOpen && !mapHasLoaded}
  <div class="absolute z-20 flex items-center justify-center w-full h-screen bg-white">
    <Loader/>
  </div>
{/if}
