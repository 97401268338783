<script>
  import { Icon } from '@steeze-ui/svelte-icon';
  import { PlusSmall } from '@steeze-ui/heroicons';
  import { MinusSmall } from '@steeze-ui/heroicons';

  export let name, value, minValue, canIncrease = true

  if(value == undefined){
    value = 1
  }

  if(minValue == undefined){
    minValue = 0
  }

  function decrease() {
    if(value > minValue){
        value --;
    }
  }

  function increase() {
    if(canIncrease){
      value ++;
    }
  }
</script>

<div class="flex items-center">
  <span class="w-6 h-6 flex justify-center touch-none items-center font-medium text-{value == minValue ? 'gray-200' : 'secondary'} select-none text-xl border-2 border-{value == minValue ? 'gray-200' : 'secondary'} rounded-full block {value == minValue ? '' : 'cursor-pointer'}" on:click={decrease}>
    <Icon src={MinusSmall} theme="solid" />
  </span>
  <input type="number" class="hidden" name={name} bind:value={value}>
  <span class="w-6 mx-6 text-xl font-semibold text-center">{value}</span>
  <span class="flex items-center justify-center block w-6 h-6 text-xl font-medium border-2 rounded-full {canIncrease ? 'cursor-pointer' : ''} select-none touch-none text-{canIncrease ? 'secondary' : 'gray-200'} border-{canIncrease ? 'secondary' : 'gray-200'}" on:click={increase}>
    <Icon src={PlusSmall} theme="solid" />
  </span>
</div>
