import { isWithinInterval, startOfDay, endOfDay } from 'date-fns';

const firstBookedAfterSelectedDate = (arrivalDate, bookedPeriods) => {
  return bookedPeriods
  .flatMap(value => value)
  .map((date) => flatpickr.parseDate(date, "Y-m-d"))
  .sort((a, b) => { return a - b })
  .filter((date) => date.valueOf() >= arrivalDate.valueOf())[0]
}

export const updateOptionsUponSelection = (selectedDates, flatpickr, bookedPeriods) => {
  if(selectedDates.length == 0 && bookedPeriods.length > 0) {
    flatpickr.set("disable", [
      function(date) {
        if(bookedPeriods.flatMap(value => value).includes(flatpickr.formatDate(date, "Y-m-d"))) {
          return true;
        }
      }
    ])
  }

  // disable dates that are booked, except for the potential departure date
  if(selectedDates.length == 2 && bookedPeriods.length > 0) {
    flatpickr.set("disable", [
      function(date) {
        if(bookedPeriods.map((period) => period.slice(1)).flatMap(value => value).includes(flatpickr.formatDate(date, "Y-m-d"))) {
          return true;
        }
      }
    ])
  }
  flatpickr.set("minDate", "today")

  if(selectedDates.length == 1){
    flatpickr.set("disable", [function(date) {
      const firstBookedAfterSelection = firstBookedAfterSelectedDate(selectedDates[0], bookedPeriods)

      if(firstBookedAfterSelection && firstBookedAfterSelection.valueOf() < date.valueOf()){
        return true;
      }

      // This could be achieved setting min date, unfortunately, it triggers the month to be switched
      // when using 2-month panel, and selected date is on the second panel
      // I guess it switches because there are no available dates, but it makes for a strange UI
      if(selectedDates[0].valueOf() > date.valueOf()){
        return true;
      }
    }])
  }
}

export const onDayCreate = (durationConfig, bookedPeriods, instance, dayElem) => {
  const onlyDeparturePossibleDates = bookedPeriods.map((period) => { return period[0] })

  const periodsOverlappingDay = periodsOverlappingDate(dayElem.dateObj, durationConfig.periods)

  let isSatToSat = false

  if(periodsOverlappingDay.length > 0){
    isSatToSat = (periodsOverlappingDay.filter((period) => { return period.satToSat }).length > 0)
  } else {
    isSatToSat = durationConfig.allTime.satToSat || false
  }

  if(isSatToSat && dayElem.dateObj.getDay() != 6) {
    dayElem.classList.add("sat-to-sat")
    dayElem.classList.add("flatpickr-disabled")
  }

  if(instance.selectedDates.length == 1){
    const periodsOverlappingStartDate = periodsOverlappingDate(instance.selectedDates[0], durationConfig.periods)

    let minimalDurationStay = 0

    if(periodsOverlappingStartDate.length > 0){
      minimalDurationStay = Math.max(...periodsOverlappingStartDate.map(period => period.minimalDurationStay));
    }
    else {
      minimalDurationStay = durationConfig.allTime.minimalDurationStay;
    }

    if(minimalDurationStay > 1) {
      if(dayElem.dateObj.getTime() > instance.selectedDates[0].getTime() && dayElem.dateObj.getTime() < instance.selectedDates[0].fp_incr(minimalDurationStay).getTime()) {
        dayElem.classList.add("min-stay")
        dayElem.setAttribute("data-min-stay", minimalDurationStay)
        dayElem.classList.add("flatpickr-disabled")
      }
    }
  }

  let booked_days = bookedPeriods.flatMap(value => value)

  // only add departure date indicator when previous day is not booked (otherwise it doesn't make sense)
  if(onlyDeparturePossibleDates.includes(instance.formatDate(dayElem.dateObj, "Y-m-d")) && !booked_days.includes(instance.formatDate(dayElem.dateObj.fp_incr(-1), "Y-m-d"))) {
    dayElem.classList.add("no-start")
  }
}

const periodsOverlappingDate = (date, periods) => {
  return periods.filter((period) => {
    const periodStart = flatpickr.parseDate(period.startDate, "Y-m-d")
    const periodEnd = flatpickr.parseDate(period.endDate, "Y-m-d")

    const interval = {
      start: startOfDay(periodStart),
      end: endOfDay(periodEnd)
    };

    return isWithinInterval(date, interval)
  })
}
