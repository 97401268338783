import { writable, get } from "svelte/store";

export const quote = writable({
  guestlist: {
    adult_count: 1,
    child_count: 0,
    infant_count: 0,
    animal_count: 0,
  }
});
