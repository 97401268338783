<script>
  import AutoComplete from 'simple-svelte-autocomplete'
  import { currentSearch } from './listingsStore.js';

  const areaManuallySelected = "Zone carte";

  export let inputClassName = "input-base block mt-4 rounded-3xl placeholder:text-gray-700"

  let locationObject = {"name" : $currentSearch.location, "bbox_area" : $currentSearch.bbox_area}

  const getItems = async (query) => {
    // don't load a search if the input is filled in with areaManuallySelected text
    if(query == areaManuallySelected.toLowerCase()){
      return []
    }

    const holiduURL = `https://api.holidu.com/old/rest/v6/search/autocomplete/${encodeURIComponent(query)}?domainId=3350&locale=fr-FR&currency=EUR`
    const response = await fetch(holiduURL)
    const json = await response.json();
    return json.items
  }

  const onChange = (e) => {
    if(e && e.bbox_area){
      $currentSearch.location = e.name
      $currentSearch.bbox_area = e.bbox_area
      if(document.getElementById("bbox_area")){
        document.getElementById("bbox_area").value = e.bbox_area
      }
      window.shouldMapBeRepositionned = true;
    }

    if(e && e.pathname){
      if(document.getElementById("search_path")){
        document.getElementById("search_path").value = e.pathname
      }
    }
  }

  const onFocus = () => {
    if(locationObject.name == areaManuallySelected){
      locationObject.name = ""
    }
  }

  window.addEventListener("toploc:custom-location-selected", function(){
    locationObject = { name: areaManuallySelected };
  });

</script>

<AutoComplete
  searchFunction={getItems}
  delay=200
  localFiltering=false
  inputClassName={inputClassName}
  dropdownClassName="mt-2 rounded-xl border-gray-100"
  noResultsText="Pas de résultat"
  hideArrow="true"
  name="location"
  placeholder="Où allez-vous ?"
  onChange={onChange}
  onFocus={onFocus}
  labelFieldName="mainPart"
  valueFieldName="pathname"
  minCharactersToSearch=2
  bind:selectedItem={locationObject}
>

  <div slot="loading">
    <span class="text-gray-500">Chargement...</span>
  </div>

  <div class="my-1 text-sm font-semibold text-gray-600" slot="item" let:item={item} let:label={label}>
    {@html label}
    <span class="block text-xs text-gray-500">
      {item.value}
    </span>
  </div>
</AutoComplete>
