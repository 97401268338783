<script>
  import { onMount } from 'svelte'

  import { Icon } from '@steeze-ui/svelte-icon';
  import { CheckCircle } from '@steeze-ui/heroicons';
  import { setupPhoneCountryCode } from '../common/phoneCountryCode'

  export let travellerInfo = {}
  export let onPhoneChange = () => {}

  onMount(() => {
    setupPhoneCountryCode(document.querySelector(".format-phone-number"), (iti) => {
      onPhoneChange(iti)
    })
  })

</script>

<div>
  <p class="explainer">Pour finaliser votre demande, vous devez créer un compte Toploc.</p>

  <fieldset class="justify-between py-2 md:flex gap-x-6">
    <div class="w-full">
      <label class="label-base" for="first_name">Prénom *</label>
      <input bind:value={travellerInfo.first_name} type="text" class="block w-full input-base" name="first_name" placeholder="Jean" autocomplete="given-name" required />
    </div>

    <div class="w-full pt-2 md:pt-0">
      <label class="label-base" for="last_name">Nom *</label>
      <input bind:value={travellerInfo.last_name} type="text" class="block w-full input-base" name="last_name" placeholder="Dupont" autocomplete="family-name" required />
    </div>
  </fieldset>

  <fieldset class="justify-between py-2 md:flex gap-x-6">
    <div class="w-full">
      <label class="label-base" for="email">Email *</label>
      <input bind:value={travellerInfo.email} type="email" class="block w-full input-base" name="email" placeholder="jean.dupont@email.com" autocomplete="email" required />
    </div>

    <div class="w-full">
      <label class="py-2 label-base" for="phone">Téléphone *</label>
      <div class="flex items-center">
        <input bind:value={travellerInfo.phone} type="tel" class="block w-full input-base format-phone-number" name="phone" required />
        <span class="z-10 invisible -ml-10" id="valid-phone-checkmark">
          <Icon src={CheckCircle} size="24px" theme="solid" class="inline mr-4 fill-primary"/>
        </span>
      </div>
    </div>
  </fieldset>
  <fieldset class="justify-between pt-2 md:pt-0 md:flex gap-x-6">
    <div class="w-full">
      <label class="label-base" for="password">Mot de passe *</label>
      <input bind:value={travellerInfo.password} type="password" class="block w-full input-base" name="password" autocomplete="new-password" required />
    </div>
  </fieldset>
</div>

<div class="mt-4 text-xs">* : champs obligatoires</div>
<div class="mb-8 divider"></div>

