<script>
  import {onMount, onDestroy, tick } from 'svelte';
  import SearchFilters from './SearchFilters.svelte'
  import { listings, currentSearch, loadListings } from './listingsStore.js';
  import SearchLocationInput from './SearchLocationInput.svelte'
  import Flatpickr from 'svelte-flatpickr/src/Flatpickr.svelte'
  import language from "flatpickr/dist/l10n/fr.js"

  export let initialSearch, closeIcon, searchIcon, amenities, propertyTypes, experiences, filterIcon, mapIcon, calendarIcon, form

  Object.keys(initialSearch).forEach(key => {
    if (initialSearch[key] === null || initialSearch[key] == '') {
      delete initialSearch[key];
    }
  });

  const defaultSearch = {
    location: "Zone carte",
    property_types: [],
    people: "0",
    amenity_ids: [],
    experience_ids: [],
    page: 1,
    order: "",
    curated_list: { id: "" }
  }

  $currentSearch = { ...defaultSearch, ...initialSearch }

  let visible = false;
  let datePickerInstance;
  let selectedPropertyTypes = []

  let datePickerValue = [
    new Date($currentSearch.start_date),
    new Date($currentSearch.end_date)
  ]

  let flatpickrOptions = {
    allowInput: true,
    locale: language.fr,
    dateFormat: 'd-m-Y',
    showMonths: document.body.getBoundingClientRect().width > 620 ? 2 : 1,
    mode: "range",
    minDate: "today",
    onChange: function(selectedDates) {
      if(selectedDates.length == 2) {
        $currentSearch.start_date = flatpickr.formatDate(selectedDates[0], "Y-m-d")
        $currentSearch.end_date = flatpickr.formatDate(selectedDates[selectedDates.length - 1], "Y-m-d")
      }
      else {
        $currentSearch.start_date = null
        $currentSearch.end_date = null
      }
    }
  }

  $: datesLabel = () => {
    if($currentSearch.start_date && $currentSearch.end_date){
      return `${new Intl.DateTimeFormat(navigator.languages).format(new Date($currentSearch.start_date))} au ${new Intl.DateTimeFormat(navigator.languages).format(new Date($currentSearch.end_date))}`
    }
    else {
      return "Dates flexibles"
    }
  }

  $: travellersLabel = () => {
    if($currentSearch.people == 0){
      return ""
    }
    if($currentSearch.people == 1){
      return " · 1 voyageur"
    }

    return ` · ${$currentSearch.people} voyageurs`
  }

  $: propertyTypesLabel = (selectedPropertyTypes) => {
    if(selectedPropertyTypes.length == 0) {
      return "Tous logements"
    }
    else if(selectedPropertyTypes.length == 1) {
      return "1 type de logement"
    }
    else {
      return `${selectedPropertyTypes.length} types de logements`
    }
  }

  const toggleSearchPanel = () => {
    if(visible) {
      visible = false
    }
    else {
      visible = true
    }
  }

  const onSubmit = async () => {
    visible = false

    loadListings(true, true)
  }

  const restoreSearchState = async (e) => {
    if(e.state?.currentSearch) {
      $currentSearch = e.state.currentSearch
      $listings = e.state.listings
    }
    else {
      $currentSearch = { ...defaultSearch, ...initialSearch }

      await tick(); // current search needs to be updated before loadListings
      loadListings(false, false)
    }
  }

  onDestroy(() => {
    window.removeEventListener('popstate', restoreSearchState)
  })

  onMount(() => {
    window.addEventListener('popstate', restoreSearchState)

    if (window.searchResults?.length > 0) {
      $listings = window.searchResults;
      window.searchResults = null;
    } else {
      loadListings(false, false, true)
    }
  })

</script>

<form id="listing-search-form" class="px-2 pb-4 text-gray-600 bg-white" bind:this={form} on:submit|preventDefault={onSubmit}>
  <div class="fixed z-40 w-full mx-auto bg-white max-w-screen-md md:relative">
    <div class="mx-2 my-2 font-medium cursor-pointer sm:mx-0 h-14 max-w-screen-md">
      <div class="flex justify-between bg-gray-50 shadow-md {visible ? 'rounded-b-none' : ''} px-1 border rounded-3xl py-2 md:px-2">
        <div class="flex items-center w-full" on:click={toggleSearchPanel}>
          <div class="w-6 h-6 p-1 mx-1 rounded-full cursor-pointer md:w-7 md:h-7">
            {@html searchIcon}
          </div>

          <div>
            <div class="text-gray-800">
              {$currentSearch.location} <span class="text-xs">{travellersLabel()} </span>
            </div>

            <div class="text-xs">
              {datesLabel()} · {propertyTypesLabel(selectedPropertyTypes)}
            </div>
          </div>
        </div>

        <SearchFilters
          currentSearch={$currentSearch}
          amenities={amenities}
          experiences={experiences}
          propertyTypes={propertyTypes}
          filterIcon={filterIcon}
          closeIcon={closeIcon}
          onFilterChange={onSubmit}
          bind:selectedPropertyTypes={selectedPropertyTypes}
          />
      </div>

      <div class="{visible ? 'block shadow-xl' : 'hidden' } relative z-20 w-full mx-auto bg-gray-50 border border-t-0 rounded-b-3xl">
        <div class="justify-between px-4 py-2 sm:flex">
        <div class="relative font-medium">
          <div class="inline cursor-pointer stroke-gray-400 absolute left-3 top-3 sm:top-[26px] font-medium w-[18px] h-[18px] z-10">
            {@html mapIcon}
          </div>

          <SearchLocationInput />
        </div>

        <div class="relative">
          <div class="inline cursor-pointer stroke-gray-400 absolute left-3 top-3 sm:top-[27px] font-medium w-4 h-4">
            {@html calendarIcon}
          </div>

          <Flatpickr
            options="{flatpickrOptions}"
            element="#date-picker"
            bind:value="{datePickerValue}"
            bind:flatpickr="{datePickerInstance}"
          >
            <div class="block mt-4 pl-8 font-medium bg-white cursor-pointer input-base min-w-[16rem] rounded-3xl xl:w-60" id="date-picker">
              <input
                class="w-full focus-visible:outline-none"
                onkeypress="return false"
                readonly="readonly"
                autocomplete="off"
                type="text"
                name="dates"
                placeholder="Quand partez-vous ?"
                data-input />

              <div on:click={() => datePickerInstance.setDate(null, true)} class="absolute w-4 h-4 cursor-pointer right-4 md:right-2 top-[12px] sm:top-[27px] stroke-gray-500">
                {@html closeIcon}
              </div>
            </div>
          </Flatpickr>

        <input type="hidden" name="start_date" bind:value={$currentSearch.start_date} />
        <input type="hidden" name="end_date" bind:value={$currentSearch.end_date} />
        <input type="hidden" name="bbox_area" bind:value={$currentSearch.bbox_area} />
        <input type="hidden" name="page" bind:value={$currentSearch.page} />
        <input type="hidden" name="order" bind:value={$currentSearch.order} />
          <input type="hidden" name="curated_list_id" bind:value={$currentSearch.curated_list.id} />

        </div>
          <select
            bind:value={$currentSearch.people}
            name="people"
            class="block mt-4 font-medium cursor-pointer input-base rounded-3xl xl:w-60"
            id="people">

            <option value="1">1 voyageur</option>
            <option value="2">2 voyageurs</option>
            <option value="3">3 voyageurs</option>
            <option value="4">4 voyageurs</option>
            <option value="5">5 voyageurs</option>
            <option value="6">6 voyageurs</option>
            <option value="7">7 voyageurs</option>
            <option value="8">8 voyageurs</option>
            <option value="9">9 voyageurs</option>
            <option value="10">10 voyageurs</option>
            <option value="11">11 voyageurs</option>
            <option value="12">12 voyageurs</option>
            <option value="13">13 voyageurs</option>
            <option value="14">14 voyageurs</option>
            <option value="15">15 et + voyageurs</option>
            <option value="20">20 et + voyageurs</option>
          </select>

        </div>

        <div class="flex justify-center w-full p-4 mt-6 border-t">
          <input type="submit" class="mx-auto text-white shadow btn-secondary hover:bg-secondary" value="Rechercher"/>
        </div>

      </div>
    </div>
  </div>

  <div class="w-full h-20 md:hidden"></div>
</form>
