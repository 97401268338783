<script>
  import { onMount } from 'svelte';
  import { loadStripe } from '@stripe/stripe-js';
  import { quote } from './quoteStore.js';
  import { notification } from '../common/notificationStore.js';

  export let stripePublicKey;

  let chargeAmount = 0;
  let paymentElement, stripeElements, stripe

  const appearance = {
    theme: 'flat',
    variables: {
      colorPrimary: '#006B52',
      fontFamily: 'Montserrat, system-ui, sans-serif',
      fontWeightNormal: 500,
      colorText: '#1F2937',
      colorBackground: '#F3F4F6',
      borderRadius: '0.5rem',
    },
    rules: {
      '.Input': {
        border: '1px solid #e5e7eb',
      },
    }
  };

  export const trySubmit = async () => {
    return await stripeElements.submit()
  }

  export const submitPayment = async (clientSecret, redirectUrl) => {
    const {error} = await stripe.confirmPayment({
      elements: stripeElements,
      clientSecret: clientSecret,
      confirmParams: {
        return_url: redirectUrl
      },
    });

    if (error) {
      $notification = { alert: error.message}
    }
  }

  onMount(async () => {
    quote.subscribe((value) => {
      chargeAmount = Math.round(value.total_price * 100);

      if(stripeElements) {
        stripeElements.update({ amount: chargeAmount });
      }
    });

    stripe = await loadStripe(stripePublicKey);

    const options = {
      mode: 'payment',
      currency: 'eur',
      amount: Math.round($quote.total_price * 100),
      captureMethod: 'manual'
    };

    stripeElements = stripe.elements({ ...options, appearance });

    paymentElement = stripeElements.create('payment');
    paymentElement.mount('#payment-element');
  });
</script>

<form class="py-4" id="payment-form">
  <div id="payment-element"></div>
  <p class="pt-4 explainer">Vous serez débité.e seulement si la réservation est confirmée par l'hôte.</p>
  <p class="explainer"><span class="font-bold">Une question ? </span>Vous pouvez joindre Toploc au <a href="tel:+33 4 58 10 52 90" class="font-bold">(+33) 4 58 10 52 90</a> (Lun-Ven 9-17h)</p>
  <p class="explainer">Vous pouvez également <a class="font-bold underline" href="/inquiries/new?listing_id={$quote.listing.id}">contacter l'hôte directement</a>.</p>
</form>
