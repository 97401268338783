<script>
  export let share_icon, facebook_icon, whatsapp_icon, twitter_icon, url, title

  let openMenu = false

  let iconClassList = "inline w-6 h-6 font-medium text-gray-600 cursor-pointer fill-current"

  const toggleShareMenu = () =>{
    // use native webshare api if possible
    if ( navigator.share ) {
      navigator.share({
        url: url,
        title: title,
      });
    }
    else {
      openMenu = !openMenu
    }
  }

</script>

<div on:click={toggleShareMenu} class="flex items-center justify-center w-8 h-8 mr-1 bg-gray-100 border border-gray-100 rounded-full shadow md:mr-4 hover:bg-gray-50">
  <div class="inline w-4 h-4 font-medium text-gray-600 cursor-pointer fill-current">
    {@html share_icon}
  </div>

  <div class:hidden="{!openMenu}" class="absolute hidden px-6 py-4 text-gray-900 bg-white border rounded shadow w-60 border-gray-50 top-16">
    <div class="text-base font-semibold text-primary">Partager l'annonce</div>

    <div onclick="window.open('https://api.whatsapp.com/send?text={url}');" class="flex items-center my-2 cursor-pointer">
      <span class="w-40 text-xs font-semibold text-gray-500">Partager sur Whatsapp</span>
      <div class="{iconClassList}">
        {@html whatsapp_icon}
      </div>
    </div>

    <div onclick="window.open('https://www.facebook.com/sharer/sharer.php?u={url}');" class="flex items-center my-2 cursor-pointer">
      <span class="w-40 text-xs font-semibold text-gray-500">Partager sur Facebook</span>
      <div class="{iconClassList}">
        {@html facebook_icon}
      </div>
    </div>

    <div onclick="window.open('https://twitter.com/share?url={url}');" class="flex items-center my-2 cursor-pointer">
      <span class="w-40 text-xs font-semibold text-gray-500">Partager sur Twitter</span>
      <div class="{iconClassList}">
        {@html twitter_icon}
      </div>
    </div>
  </div>
</div>
