<script>
  import Flatpickr from 'svelte-flatpickr/src/Flatpickr.svelte'
  import language from "flatpickr/dist/l10n/fr.js"

  import { fade, slide } from 'svelte/transition';
  import OutClick from 'svelte-outclick'
  import Times from "../common/icons/Times.svelte";
  import QuoteSummaryTable from "./QuoteSummaryTable.svelte";
  import GuestlistSelector from "./GuestlistSelector.svelte";
  import { quote } from './quoteStore.js';
  import { strftime } from '../common/strftime';
  import { updateOptionsUponSelection, onDayCreate } from './flatpickrBookingHandler';

  export let listingId, startDate, endDate, adultCount, formattedBasePrice, bookedPeriods, animalsAccepted, durationConfig, capacity

  $quote.startDate = startDate
  $quote.endDate = endDate
  $quote.guestlist.adult_count = adultCount

  let datePickerInstance;

  let datePickerValue = [
    new Date($quote.startDate),
    new Date($quote.endDate)
  ]

  let quoteResult = {}
  let travellersModalOpen = false
  let loadingQuote = false
  let mobileModalOpen = false
  let screenWidth
  let scrollPosition = 0
  let scrollPositionWhenModalOpened = 0

  const addDatesHandler = () => {
    if(document.body.getBoundingClientRect().width > 620){
      datePickerInstance.open()
    }
    else {
      openQuoteModal()
    }
  }

  const openQuoteModal = () => {
    mobileModalOpen = true
    scrollPositionWhenModalOpened = scrollPosition

    // Fixes an issue with layout jumping on iOS: https://stackoverflow.com/questions/72942602/prevent-page-scroll-when-focusing-input-located-inside-of-a-modal
    document.body.style.overflow = 'auto'
    document.body.style.position = 'relative'
    document.body.style.height = '100%'

    document.documentElement.style.overflow = 'auto'
    document.documentElement.style.position = 'relative'
    document.documentElement.style.height = '100%'
  }

  const closeQuoteModal = () => {
    mobileModalOpen = false
    document.body.style.overflow = 'auto'
    document.body.style.position = 'static'
    document.body.style.height = 'auto'

    window.scrollTo(0, scrollPositionWhenModalOpened)

    document.documentElement.style.overflow = 'auto'
    document.documentElement.style.position = 'static'
    document.documentElement.style.height = 'auto'
  }

  $: quoteReadyForCheckout = $quote.startDate && $quote.endDate && quoteResult.total_price > 0

  $: travellersLabel = () => {
    let labels = []

    if ($quote.guestlist.adult_count == 1) {
      labels.push('1 adulte')
     }
     else if ($quote.guestlist.adult_count > 1) {
      labels.push(`${$quote.guestlist.adult_count} adultes`)
    }

    if ($quote.guestlist.child_count == 1) {
      labels.push('1 enfant')
     }
     else if ($quote.guestlist.child_count > 1) {
      labels.push(`${$quote.guestlist.child_count} enfants`)
    }

    if ($quote.guestlist.infant_count == 1) {
      labels.push('1 bébé')
     }
     else if ($quote.guestlist.infant_count > 1) {
      labels.push(`${$quote.guestlist.infant_count} bébés`)
    }

    if ($quote.guestlist.animal_count == 1) {
      labels.push('1 animal')
     }
     else if ($quote.guestlist.animal_count > 1) {
      labels.push(`${$quote.guestlist.animal_count} animaux`)
    }


    return labels.join(', ')
  }

  quote.subscribe(async (value) => {
    if(value.startDate && value.endDate) {
      loadingQuote = true

      const response = await fetch(`/quotes/new?start_date=${encodeURIComponent(value.startDate)}&end_date=${encodeURIComponent(value.endDate)}&listing_id=${listingId}&adult_count=${$quote.guestlist.adult_count}`)

      if (response.ok) {
        const data = await response.json()
        quoteResult = data
        loadingQuote = false
      } else {
        quoteResult = {}
        loadingQuote = false
      }
    }
  });

  let flatpickrOptions = {
    allowInput: true,
    locale: language.fr,
    dateFormat: 'j M',
    showMonths: document.body.getBoundingClientRect().width > 620 ? 2 : 1,
    mode: "range",
    minDate: "today",
    onChange: function(selectedDates, _dateStr, instance) {
      updateOptionsUponSelection(selectedDates, instance, bookedPeriods)

      if(selectedDates.length == 2) {
        $quote.startDate = flatpickr.formatDate(selectedDates[0], "Y-m-d"),
        $quote.endDate = flatpickr.formatDate(selectedDates[selectedDates.length - 1], "Y-m-d")
      }
      else {
        $quote.startDate = null
        $quote.endDate = null
      }
    },
    onReady: function(selectedDates, _dateStr, instance) {
      updateOptionsUponSelection(selectedDates, instance, bookedPeriods)
    },

    onDayCreate: function(_dObj, _dStr, instance, dayElem){
      onDayCreate(durationConfig, bookedPeriods, instance, dayElem)
    },
  }
</script>


<svelte:window bind:innerWidth={screenWidth} bind:scrollY={scrollPosition}/>

{#if mobileModalOpen}
  <div transition:fade="{{duration: 100}}" class="fixed top-0 left-0 z-[60] w-screen h-screen quote-modal-overlay bg-black/80 transition-all"></div>
{/if}

<div class="z-[65] relative px-1 md:px-0">
  <div class="w-full bg-white border rounded-t-lg shadow-lg md:rounded-lg">

    {#if mobileModalOpen || screenWidth > 768}
      <div transition:slide="{{duration: 200}}">
        <div class="flex items-center justify-between p-4 font-bold border-b rounded-t-lg bg-gray-50">
          <div>
            <h3>Tarif et disponibilité</h3>
          </div>
          <span class="p-2 bg-gray-200 rounded-full cursor-pointer md:hidden hover:bg-gray-300 transition-all" on:click={closeQuoteModal}>
            <Times className="w-4 h-4 text-gray-600" />
          </span>
        </div>

        <div class="p-4 pb-0">
          <div class="hidden py-0 font-medium text-gray-600 md:block">
            {#if quoteResult.price_per_night}
              <span class="text-lg font-bold">
                {quoteResult.total_price_per_night} {quoteResult.currency}
              </span>
              {:else}
                À partir de <span class="text-lg font-bold">{formattedBasePrice}</span>
            {/if}
            <span>
              / nuit
            </span>
          </div>

          <div class="flex my-2 gap-x-2">
            <Flatpickr
              options="{flatpickrOptions}"
              element="#date-picker"
              bind:value="{datePickerValue}"
              bind:flatpickr="{datePickerInstance}"
            >
              <div class="w-1/2">
                <div class="py-2 text-xs font-semibold text-gray-500 label-base">Dates</div>

                <div class="relative px-2 input-base h-[40px]" id="date-picker">
                  <input
                    class="w-full pr-2 focus-visible:outline-none"
                    onkeypress="return false"
                    readonly="readonly"
                    autocomplete="off"
                    type="text"
                    name="dates"
                    placeholder="Dates"
                    data-input />
                </div>
              </div>
            </Flatpickr>

            <div class="w-1/2">
              <div class="py-2 text-xs font-semibold text-gray-500 label-base">Voyageurs</div>
              <input type="text" class="w-full px-2 cursor-pointer input-base" readonly value={travellersLabel()} on:click={() => travellersModalOpen = true} />
            </div>

            {#if travellersModalOpen}
              <OutClick on:outclick={() => travellersModalOpen = false} >
                <div transition:slide class="absolute z-10 bg-white border rounded-lg shadow-md h-[60vh] md:h-auto bottom-0 left-0 w-full md:w-full">
                  <GuestlistSelector bind:travellersModalOpen animalsAccepted={animalsAccepted} capacity={capacity} />
                </div>
              </OutClick>
            {/if}
          </div>
        </div>

        <div class="p-4 pt-0">
          <QuoteSummaryTable bind:quote={quoteResult} />
        </div>
      </div>
      {/if}

    <div class="flex items-center justify-between px-4 md:pt-0 md:p-4 md:block">
      <div class="py-0 text-xs font-medium text-gray-600 md:hidden">
        {#if quoteReadyForCheckout}
          <span class="text-base font-bold">
            {quoteResult.total_price_per_night} {quoteResult.currency}
          </span>
          {:else}
            À partir de <span class="text-lg font-bold">{formattedBasePrice}</span>
        {/if}
        <span>
          / nuit
        </span>
        <div class="font-bold underline cursor-pointer" on:click={openQuoteModal}>
          {#if quoteReadyForCheckout}
            {strftime("%e %b", $quote.startDate)} au {strftime("%e %b", $quote.endDate)}
        {/if}
        </div>
      </div>

      {#if loadingQuote}
        <button disabled class="block my-4 md:w-full btn-primary animate-pulse">...</button>
      {:else if quoteReadyForCheckout}
        <a class="block my-4 text-center md:w-full btn-primary" href="/checkouts/new?listing_id={listingId}&start_date={$quote.startDate}&end_date={$quote.endDate}&adult_count={$quote.guestlist.adult_count}&child_count={$quote.guestlist.child_count}&infant_count={$quote.guestlist.infant_count}&animal_count={$quote.guestlist.animal_count}">Réservation</a>
      {:else}
        <button class="block my-4 md:w-full btn-primary" on:click={addDatesHandler}>Ajouter des dates</button>
      {/if}
    </div>
  </div>
</div>
