<script>
  import { fade } from 'svelte/transition';

  export let backButton, arrowRight, experience
  export let displayAsModal = false
  let showModal = false
  let thirdPartyExperiences = experience.third_party_experiences

  $: bgOpacity = displayAsModal ? "0.7" : "0"

</script>

<span on:click={() => {showModal = true}} class="flex items-center py-px font-semibold cursor-pointer tag">
  <span>{thirdPartyExperiences.length} offres</span>
  {@html arrowRight}
</span>

{#if showModal}
  <div transition:fade="{{duration: 80}}" class="fixed top-0 left-0 z-50 w-full h-screen sm:pt-8" on:click={() => showModal = false} style="background: rgba(0,0,0,{bgOpacity});">
    <div on:click|stopPropagation class="container relative py-6 mx-auto shadow-xl sm:rounded-lg bg-gray-50 max-w-screen-sm">
      <div class="absolute flex justify-end top-4 right-4" on:click={() => showModal = false}>
        <div class="p-2 bg-gray-200 rounded-full cursor-pointer hover:bg-gray-300 transition-all">
          {@html backButton}
        </div>
      </div>
      <div class="px-6 overflow-auto" style="height: calc(100vh - 8rem);">
        <h2 class="pb-2 mb-2 text-lg font-semibold border-b border-gray-200 text-primary">{thirdPartyExperiences.length} offres de {experience.display_fr}</h2>

        <ul class="my-6">
          {#each thirdPartyExperiences.sort((a,b) => { return a.distance - b.distance }) as thirdPartyExperience}
            <li class="items-center block w-full pb-4 my-4 border-b border-gray-200 items-top gap-x-8 sm:grid grid-cols-8">

              <img class="object-cover w-full h-40 rounded sm:h-24 sm:w-36 col-span-2 border-secondary" loading="lazy" src="{thirdPartyExperience.images_url[0]}" alt="{experience.display_fr}">

              <h4 class="self-start my-4 col-span-4 sm:my-0">
                <div class="text-base font-medium sm:text-sm">{thirdPartyExperience.title}</div>
                <div class="mt-1 text-xs text-gray-500">
                  <span class="text-sm font-semibold text-gray-700">{thirdPartyExperience.prices["EUR"]}€</span>
                  {#if thirdPartyExperience.organization.length > 0}
                  &centerdot; organisé par {thirdPartyExperience.organization}
                  {/if}
                </div>

              </h4>
              <a class="min-w-[100px] col-span-2 justify-center sm:justify-start py-2 sm:py-px mt-6 sm:mt-0 flex items-center font-semibold cursor-pointer tag"
                href="/third_party_experience_links/{thirdPartyExperience.id}"
                rel="sponsored"
                target="_blank">
                Je réserve
                {@html arrowRight}
              </a>
            </li>
          {/each}
        </ul>
      </div>
    </div>
  </div>
{/if}
