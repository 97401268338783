<script>
  export let options
  export let selectedValues
  export let selection = []
  export let fieldName

  if(selectedValues && selectedValues.length > 0){
    selection = options.filter(function(item){
      return selectedValues.includes(item.value)
    });
  }

  const swipeLeft = () => {
    document.querySelectorAll(`#${fieldName}-grid label`)[swipeAmount('left')].scrollIntoView({behavior: 'smooth', block: "nearest"})
  }

  const swipeRight = () => {
    document.querySelectorAll(`#${fieldName}-grid label`)[swipeAmount('right')].scrollIntoView({behavior: 'smooth', block: "nearest"})
  }

  const swipeAmount = (direction) => {
    const swipeStep = document.querySelector(`#${fieldName}-grid`).scrollWidth / options.length
    const offsetLeft = document.querySelector(`#${fieldName}-grid`).scrollLeft
    const containerWidth = document.querySelector(`#${fieldName}-grid`).offsetWidth

    const elementsOffset = Math.round(offsetLeft / swipeStep)
    const elementsShown = Math.round(containerWidth / swipeStep)

    if(direction == 'right'){
      return Math.round(Math.min(elementsShown + elementsOffset + elementsShown / 2, options.length - 1))
    }
    else {
      return Math.max(elementsOffset - elementsShown, 0)
    }
  }

  const toggleItem = (option) => {
    if(selection.includes(option)) {
      selection = selection.filter(function(item){
        return item.value != option.value;
      });
    }
    else {
      selection = [...selection, option];
    }
  }
</script>

<div class="flex items-center">
  <div class="hidden p-1 mr-2 bg-white border rounded-full shadow-lg md:block hover:scale-110 transition" on:click={swipeLeft}>
    <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M7.05 9.293L6.343 10 12 15.657l1.414-1.414L9.172 10l4.242-4.243L12 4.343z"/></svg>
  </div>

  <div id="{fieldName}-grid" class="flex w-full overflow-x-auto snap-mandatory snap-x gap-x-8 hide-scrollbar">
    {#each options as option}
      <label for="{fieldName}_{option.value}" on:click|preventDefault={toggleItem(option)} class="text-center cursor-pointer snap-center">
        <input type="checkbox" checked="{selection.includes(option) ? true : false}" class="hidden peer" value="{option.value}" id="{fieldName}_{option.value}" name="{fieldName}[]"/>

        {@html option.icon}

        <div class="mt-2 mb-2 text-xs font-semibold text-gray-700 whitespace-nowrap">{option.label}</div>
      </label>
    {/each}
  </div>

  <div class="hidden p-1 ml-2 bg-white border rounded-full shadow-lg md:block hover:scale-110 transition" on:click={swipeRight}>
    <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"/></svg>
  </div>
</div>
