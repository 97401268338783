<script>
  import Tooltip from '../common/Tooltip.svelte';
  import { Icon } from '@steeze-ui/svelte-icon';
  import { InformationCircle } from '@steeze-ui/heroicons';

  export let quote

  const currencyFormatter = new Intl.NumberFormat('fr-FR', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });

  $: nightsLabel = () => {
    if (quote.nights_count == 1) {
      return '1 nuit'
    } else {
      return `${quote.nights_count} nuits`
    }
  }
</script>

{#if quote.total_price}
  <div class="flex justify-between py-1 text-sm font-medium">
    <span class="text-gray-600">{quote.price_per_night} {quote.currency} x {nightsLabel()}</span>
    <span>
      {quote.price_for_stay} {quote.currency}
    </span>
  </div>

  <div class="flex justify-between py-1 text-sm font-medium">
    <span class="flex items-center gap-x-1">
      <Tooltip anchor="Frais de service" content="Nous ne prenons pas de frais de service pour les voyageurs" classesWrapper="font-medium text-gray-600 relative" />
      <Icon src={InformationCircle} size="18px" class="text-primary" theme="solid"/>
      </span>
    <span>
      0 {quote.currency}
    </span>
  </div>

  {#if quote.cleaning_fee > 0}
    <div class="flex justify-between py-1 text-sm font-medium">
      <span class="text-gray-600">Frais de ménage</span>
      <span>
        {quote.cleaning_fee} {quote.currency}
      </span>
    </div>
  {:else if quote.cleaning_fee == 0}
    <div class="flex justify-between py-1 text-sm font-medium">
      <span class="text-gray-600">Frais de ménage</span>
      <span>
        Inclus
      </span>
    </div>
  {/if}

  {#if quote.tourist_tax > 0}
    <div class="flex justify-between py-1 text-sm font-medium">
      <span class="text-gray-600">Taxe de séjour</span>
      <span>
        { currencyFormatter.format(quote.tourist_tax) } {quote.currency}
      </span>
    </div>
  {/if}
  <div class="divider"></div>

  <div class="flex justify-between py-2 font-bold text-gray-600">
    <span class="">Total</span>
    <span>
      { currencyFormatter.format(quote.total_price) } {quote.currency}
    </span>
  </div>
{/if}
