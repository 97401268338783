<script>
  import { fade } from 'svelte/transition';

  export let itemsCount, content, closeButton, label, arrow;

  let showModal = false;

  window.addEventListener("toploc:escape-key-pressed", function(){
    if(showModal) {
      showModal = false
    }
  })
</script>

{#if itemsCount > 0}
  <div class="flex items-center my-6 text-sm font-bold cursor-pointer gap-x-1 text-secondary" on:click={()=> showModal = true}>
    Afficher les {itemsCount} {label} {@html arrow}
  </div>
{:else}
  <div class="my-6 text-sm font-semibold text-gray-500 cursor-pointer">Cette annonce n'a pas encore d'{label}.</div>
{/if}

{#if showModal}
  <div transition:fade="{{duration: 80}}" class="fixed top-0 left-0 z-50 w-full h-screen p-2 sm:p-6" on:click={() => showModal = false} style="background: rgba(0,0,0,0.7);">
    <div on:click|stopPropagation class="container relative py-6 mx-auto rounded-lg shadow-xl bg-gray-50 max-w-screen-sm">
      <div class="absolute flex justify-end top-4 right-6" on:click={() => showModal = false}>
        <div class="p-2 bg-gray-200 rounded-full cursor-pointer hover:bg-gray-300 transition-all">
          {@html closeButton}
        </div>
      </div>
      <div class="px-6 overflow-auto" style="height: calc(100vh - 8rem);">
        {@html content}
      </div>
    </div>
  </div>
{/if}
