<script>
  import { onDestroy } from 'svelte';
  import { fade } from 'svelte/transition';

  export let images, closeButton;

  let isModalVisible = false;

  function showModal(){
    document.querySelector("body").classList.add("overflow-hidden")
    isModalVisible = true;
  }

  function hideModal(){
    document.querySelector("body").classList.remove("overflow-hidden")
    isModalVisible = false;
  }

  function handleKeyDown(event){
    switch(event.key) {
      case "Escape":
        if(isModalVisible) {
        hideModal()
        }
      break;
    }
  }

  window.addEventListener("toploc:photo-clicked", showModal)
  window.addEventListener("keyup", handleKeyDown)

  onDestroy(() => {
    window.removeEventListener("toploc:photo-clicked", showModal)
    window.removeEventListener("keyup", handleKeyDown)
  })
</script>

{#if isModalVisible}
  <div transition:fade="{{duration: 80}}" id="gallery-modal" class="fixed top-0 left-0 z-50 w-full h-screen p-0 text-center bg-gray-100" on:click={hideModal}>
    <div on:click|stopPropagation class="w-full h-screen px-2 pt-0 pb-4 mx-auto overflow-auto max-w-screen-xl">
      <div class="sticky flex justify-start my-6 top-4" on:click={hideModal}>
        <div class="p-4 rounded-full shadow-lg cursor-pointer bg-primary hover:bg-primary-light transition-all">
        {@html closeButton}
        </div>
      </div>

      <div class="mb-24 grid grid-cols-1 lg:grid-cols-2 gap-8">
        {#each images as image}
          <img src="{image}" class="rounded-lg" alt="">
          {/each }
      </div>
    </div>
  </div>
{/if}

