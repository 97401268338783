<script>
  import { fade } from 'svelte/transition';
  import ThirdPartyExperienceChoiceModal from './ThirdPartyExperienceChoiceModal.svelte';

  export let closeButton, backButton, chevron, arrowRight, experiences, icons

  let showModal = false;

  window.addEventListener("toploc:escape-key-pressed", function(){
    if(showModal) {
      showModal = false
    }
  })
</script>

{#if Object.keys(experiences).length > 0}
  <div class="flex items-center my-6 text-sm font-bold cursor-pointer gap-x-1 text-secondary" on:click={()=> showModal = true}>
    Afficher toutes les experiences {@html chevron}
  </div>
{:else}
  <div class="my-6 text-sm font-semibold text-gray-500 cursor-pointer">Cette annonce n'a pas encore d'experience.</div>
{/if}

{#if showModal}
  <div transition:fade="{{duration: 80}}" class="fixed top-0 left-0 z-50 w-full h-screen p-2 sm:p-6" on:click={() => showModal = false} style="background: rgba(0,0,0,0.7);">
    <div on:click|stopPropagation class="container relative py-6 mx-auto rounded-lg shadow-xl bg-gray-50 max-w-screen-sm">
      <div class="absolute flex justify-end top-4 right-6" on:click={() => showModal = false}>
        <div class="p-2 bg-gray-200 rounded-full cursor-pointer hover:bg-gray-300 transition-all">
          {@html closeButton}
        </div>
      </div>
      <div class="px-6 overflow-auto" style="height: calc(100vh - 8rem);">
        {#each Object.keys(experiences) as experienceType}
          <div class="pb-2 text-lg font-semibold border-b border-gray-200 text-primary">{experiences[experienceType][0].experience_type_fr}</div>
          <ul class="my-6">
            {#each experiences[experienceType] as experience }
              <li class="flex my-4">
                <span>
                  {@html icons[experience.category_name]}
                </span>
                <span class="flex items-center justify-between w-full text-sm font-medium">
                  { experience.display_fr }

                  {#if experience.third_party_experiences.length == 1 }
                    <span class="flex min-w-[100px] items-center py-px font-semibold tag">
                      <a href="/third_party_experience_links/{experience.third_party_experiences[0].id}" rel="sponsored" target="_blank">Je réserve</a>
                      {@html arrowRight}
                    </span>
                  {/if}

                  {#if experience.third_party_experiences.length > 1 }
                    <ThirdPartyExperienceChoiceModal backButton={backButton} arrowRight={arrowRight} experience={experience}/>
                  {/if}
                </span>
              </li>
            {/each}
          </ul>
        {/each}
      </div>
    </div>
  </div>
{/if}
