<script>
  import NumberInput from '../host/NumberInput'
  import Times from "../common/icons/Times.svelte";
  import { quote } from './quoteStore.js';

  export let travellersModalOpen, animalsAccepted, capacity

  $: canIncrease = $quote.guestlist.adult_count + $quote.guestlist.child_count < capacity
</script>

<div class="relative pb-16">
  <div class="flex items-center justify-between px-6 py-4 font-bold border-b rounded-t-lg bg-gray-50">
    <div>
      <h3>Voyageurs</h3>
    </div>
      <span class="p-2 bg-gray-200 rounded-full cursor-pointer hover:bg-gray-300 transition-all" on:click={() => travellersModalOpen = false}>
        <Times className="w-4 h-4 text-gray-600" />
      </span>
  </div>
  <div class="flex items-center justify-between px-6 py-3 text-sm font-medium border-b gap-x-2">
    <span>Adultes</span>
    <NumberInput name="adult_count" bind:value={$quote.guestlist.adult_count} minValue=1 canIncrease={canIncrease} />
  </div>
  <div class="flex items-center justify-between px-6 py-3 text-sm font-medium border-b gap-x-2">
    <span>Enfants (2 à 12 ans)</span>
    <NumberInput name="child_count" bind:value={$quote.guestlist.child_count} minValue=0 canIncrease={canIncrease} />
  </div>
  <div class="flex items-center justify-between px-6 py-3 text-sm font-medium border-b gap-x-2">
    <span>Bébés (moins de 2 ans)</span>
    <NumberInput name="infant_count" bind:value={$quote.guestlist.infant_count} minValue=0 />
  </div>
  {#if animalsAccepted}
    <div class="flex items-center justify-between px-6 py-3 text-sm font-medium border-b gap-x-2">
      <span>Animaux</span>
      <NumberInput name="pet_count" bind:value={$quote.guestlist.animal_count} minValue=0 />
    </div>
  {/if}
  <div class="absolute bottom-0 w-full px-4">
    <button class="block w-full my-4 btn-primary" on:click={() => travellersModalOpen = false}>Valider</button>
  </div>
</div>
