<script>
  import { currentSearch, filtersOpen } from './listingsStore.js';
  import RangeSlider from "svelte-range-slider-pips";
  import MultipleItemSelector from './MultipleItemSelector.svelte'

  export let filterIcon, closeIcon, amenities, experiences, propertyTypes, selectedPropertyTypes

  export let onFilterChange = () => {}
  let filtersCount = 0

  $: {
    filtersCount = 0

    if($currentSearch.animals_accepted == "1") {
      filtersCount += 1
    }

    if($currentSearch.individuals_only == "1") {
      filtersCount += 1
    }

    if($currentSearch.ancv_accepted == "1") {
      filtersCount += 1
    }

    if($currentSearch.accessibility == "1") {
      filtersCount += 1
    }

    if($currentSearch.amenity_ids.length > 0) {
      filtersCount += $currentSearch.amenity_ids.length
    }

    if($currentSearch.experience_ids && $currentSearch.experience_ids.length > 0) {
      filtersCount += $currentSearch.experience_ids.length
    }

    if($currentSearch.property_types && $currentSearch.property_types.length > 0) {
      filtersCount += $currentSearch.property_types.length
    }

    if($currentSearch.min_price > defaultLowerBound || $currentSearch.max_price < defaultUpperBound) {
      filtersCount += 1
    }

    if($currentSearch.train_station_proximity && $currentSearch.train_station_proximity != defaultTrainStationProximity) {
      filtersCount += 1
    }

    if($currentSearch.curated_list?.name) {
      filtersCount += 1
    }
  }

  const defaultLowerBound = 0
  const defaultUpperBound = 1000
  const defaultTrainStationProximity = 0

  if(!$currentSearch.min_price) {
    $currentSearch.min_price = defaultLowerBound
  }
  if(!$currentSearch.max_price) {
    $currentSearch.max_price = defaultUpperBound
  }

  let priceRange = [$currentSearch.min_price, $currentSearch.max_price]

  $: {
    $currentSearch.min_price = priceRange[0]
    $currentSearch.max_price = priceRange[1]

    $currentSearch.amenity_ids = Object.keys(selectedAmenities).filter((k) => {
      if(selectedAmenities[k] == true) {
        return selectedAmenities[k]
      }
    })
  }

  if(!$currentSearch.train_station_proximity) {
    $currentSearch.train_station_proximity = defaultTrainStationProximity
  }

  let selectedExperiences = []
  let selectedPropertyTypesLabel = ""


  let selectedExperiencesLabel = ""
  let selectedAmenities = {}

  amenities.map((amenity) => {
    selectedAmenities[amenity[0]] = $currentSearch.amenity_ids.includes(amenity[0])
  })

  const resetFilters = () => {
    $currentSearch = {
      ...$currentSearch,
      animals_accepted: false,
      individuals_only: false,
      ancv_accepted: false,
      accessibility: false,
      amenity_ids: [],
      experience_ids: [],
      property_types: [],
      train_station_proximity: defaultTrainStationProximity
    }

    Object.keys(selectedAmenities).map((k) => { selectedAmenities[k] = false })

    priceRange = [defaultLowerBound, defaultUpperBound]
    selectedExperiences = []
    selectedPropertyTypes = []
  }

  const removeListFilter = () => {
    $currentSearch.curated_list = {}
  }

  const onSubmit = () => {
    toggleFiltersSection()
    onFilterChange()
  }

  const toggleFiltersSection = () => {
    if($filtersOpen) {
      document.body.classList.remove("overflow-hidden")
      $filtersOpen = false
    }
    else {
      document.body.classList.add("overflow-hidden")
      $filtersOpen = true
    }
  }

  $: {
    if(selectedExperiences.length == 0) {
      selectedExperiencesLabel = ""
    }

    else if(selectedExperiences.length == 1) {
      selectedExperiencesLabel = `1 / ${experiences.length} sélectionnée`
    }

    else if(selectedExperiences.length > 1) {
      selectedExperiencesLabel = `${selectedExperiences.length} / ${experiences.length} sélectionnées`
    }
  }

  $: {
    if(selectedPropertyTypes.length == 0) {
      selectedPropertyTypesLabel = ""
    }

    else if(selectedPropertyTypes.length == 1) {
      selectedPropertyTypesLabel = `1 / ${propertyTypes.length} sélectionné`
    }

    else if(selectedPropertyTypes.length > 1) {
      selectedPropertyTypesLabel = `${selectedPropertyTypes.length} / ${propertyTypes.length} sélectionnés`
    }
  }

</script>

<div class="p-2.5 bg-gray-200 border-gray-300 border flex items-center relative rounded-full sm:rounded-2xl cursor-pointer {filtersCount > 0 ? 'border-2 border-primary' : ''}" on:click={toggleFiltersSection}>
  <span class="hidden mr-2 text-xs font-semibold sm:inline">Filtres</span>
  <span class="w-4 h-4">{@html filterIcon}</span>
  {#if filtersCount > 0}
    <span class="absolute -top-1 -right-1 flex items-center justify-center w-4 h-4 text-[0.6rem] font-bold text-gray-200 rounded-full bg-primary">
      {filtersCount}
    </span>
  {/if}
</div>

<div class="overflow-auto fixed top-0 left-0 px-0 md:px-2 z-50 w-screen h-screen pt-4 top-0 bg-black/40 transition-all { $filtersOpen ? 'visible opacity-100' : 'invisible opacity-0 delay-100' }">
  <div class="z-50 flex flex-col w-full mx-auto mt-4 overflow-auto bg-white shadow-lg filter-container lg:left-0 transition-all duration-[250] { $filtersOpen ? 'translate-y-0 delay-100' : 'translate-y-[90vh]' } lg:right-0 max-w-screen-md top-8 border-primary-dark rounded-t-3xl md:rounded-b-xl lg:h-5/6 max-h-[90dvh]">
    <div class="relative flex justify-center p-4 border-b">
      <div class="absolute top-4 left-4" on:click={toggleFiltersSection}>
        <div class="w-8 h-8 p-1.5 bg-gray-200 rounded-full select-none cursor-pointer fill-current hover:bg-gray-300 transition-all">
          {@html closeIcon}
        </div>
      </div>
      <span class="font-semibold">Filtres</span>
    </div>

    <div class="p-4 pb-0 overflow-auto md:px-8">

      <h4 class="mb-4 font-semibold text-primary">Prix par nuitée</h4>

      <div class="py-4">
        <RangeSlider id="price-slider" bind:values={priceRange} max={defaultUpperBound} float={true} hoverable={true} prefix="€" />
      </div>

      <div class="flex items-center justify-between mx-auto max-w-[16rem]">
        <span>
          <span class="text-gray-500">€</span>
          <input type="numeric" class="w-24 input-base" bind:value={priceRange[0]} name="min_price">
        </span>
        -
        <span>
          <span class="text-gray-500">€</span>
          <input type="numeric" class="w-24 input-base" bind:value={priceRange[1]} name="max_price">
        </span>
      </div>

      <div class="divider"></div>

      {#if $currentSearch.curated_list?.name}
        <h4 class="mb-4 font-semibold text-primary">La sélection Toploc</h4>
        <span class="relative">
          <span class="tag">{$currentSearch.curated_list.name}</span>
          <button class="absolute w-5 h-5 p-1 bg-gray-200 rounded-full fill-current transition-all -right-3 -top-2 hover:bg-gray-300" on:click={removeListFilter}>
            {@html closeIcon}
        </span>
        <div class="divider"></div>
      {/if}

      <h4 class="mb-4 font-semibold text-primary">Les plus recherchés</h4>
      <div class="flex items-center justify-between my-6 md:my-4">
        <label class="w-full label-base" for="individuals_only">Annonces de particulier uniquement</label>
        <input class="mr-2 search" type="checkbox" value="1" bind:checked={$currentSearch.individuals_only} name="individuals_only" id="individuals_only">
      </div>

      <div class="flex items-center justify-between my-6 md:my-4">
        <label class="w-full label-base" for="animals_accepted">Animaux acceptés</label>
        <input class="mr-2 search" type="checkbox" value="1" bind:checked={$currentSearch.animals_accepted} name="animals_accepted" id="animals_accepted">
      </div>

      <div class="flex items-center justify-between my-6 md:my-4">
        <label class="w-full label-base" for="accessibility">Accès PMR</label>
        <input class="mr-2 search" type="checkbox" value="1" bind:checked={$currentSearch.accessibility} name="accessibility" id="accessibility">
      </div>

      <div class="flex items-center justify-between my-6 md:my-4">
        <label class="w-full label-base" for="ancv_accepted">Chèques ANCV acceptés</label>
        <input class="mr-2 search" type="checkbox" value="1" bind:checked={$currentSearch.ancv_accepted} name="ancv_accepted" id="ancv_accepted">
      </div>

      <div class="divider"></div>

      <h4 class="mb-6 font-semibold text-primary">
        Type de logement<span class="hidden mx-2 md:inline"></span><span class="block md:inline explainer">{selectedPropertyTypesLabel}</span>
      </h4>
      <MultipleItemSelector
        options="{propertyTypes}"
        fieldName="property_types"
        selectedValues={$currentSearch.property_types}
        bind:selection={$currentSearch.property_types}
      />

      <div class="divider"></div>

      <h4 class="mb-6 font-semibold text-primary">
        Activités à proximité<span class="hidden mx-2 md:inline"></span><span class="block md:inline explainer">{selectedExperiencesLabel}</span>
      </h4>

      <div class="pb-8">
        <MultipleItemSelector
          options="{experiences}"
          fieldName="experience_ids"
          selectedValues={$currentSearch.experience_ids}
          bind:selection={$currentSearch.experience_ids}
        />
      </div>

      <div class="divider"></div>

      <h4 class="mb-4 font-semibold text-primary">Autres critères</h4>

      <input type="hidden" name="amenity_ids[]" value="" autocomplete="off">

      {#each amenities as amenity}
        <div class="flex items-center justify-between my-6 md:my-4">
          <label class="w-full label-base" for="amenity_ids_{amenity[0]}">{amenity[1]}</label>
          <input class="mr-2 search" type="checkbox" value="{amenity[0]}" bind:checked="{selectedAmenities[amenity[0]]}" name="amenity_ids[]" id="amenity_ids_{amenity[0]}">
        </div>
      {/each}

      <div class="divider"></div>

      <h4 class="flex justify-between pb-4 mb-2 font-semibold sm:mb-1 text-primary">
        <label for="train_station_proximity" class="w-full">
          Proximité d'une gare <span class="explainer">(Moins de 10km)</span>
        </label>
        <input class="mr-2 search" type="checkbox" value="10" bind:checked={$currentSearch.train_station_proximity} name="train_station_proximity" id="train_station_proximity">
      </h4>
    </div>

    <div class="flex justify-center w-full p-4 bg-white border-t">
      <button class="md:absolute btn left-6" on:click|preventDefault={resetFilters}>Effacer les filtres</button>
      <input type="submit" class="mx-auto text-white shadow btn-secondary hover:bg-secondary" on:click|preventDefault={onSubmit} value="Filtrer les logements"/>
    </div>
  </div>
</div>

<style>
  :root {
  --range-slider: #006B52;
  --range-handle: #EA9646;
  --range-handle-focus: #EA9646;
  --range-handle-inactive: var(--range-handle);
  --range-float: #F6F5EE;
  --range-float-inactive: var(--range-float);
  --range-float-text: #1B3839;

  }

  :global(.rangeSlider#price-slider, .rangeSlider#train-station-proximity-slider) {
    height: 4px;
  }

  :global(.rangeSlider#price-slider .rangeHandle, .rangeSlider#train-station-proximity-slider) {
    top: 0.1em;
  }
</style>
